import { Button, Form, Input, Space, QRCode } from "antd";
import { useRef, useState } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

function App() {
  const [form] = Form.useForm();
  const [qrValues, setQRValues] = useState("");
  const qrCodeRef = useRef(null);
  const [size, setSize] = useState(160);

  const onFinish = (values) => {
    setQRValues(values.url);
  };

  const handleDownload = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const downloadLink = document.createElement("a");
    downloadLink.download = "qrcode.png";
    downloadLink.href = canvas.toDataURL("image/png");
    downloadLink.click();
  };

  const increase = () => {
    setSize((prevSize) => {
      const newSize = prevSize + 10;
      if (newSize > 300) {
        return 300;
      }
      return newSize;
    });
  };

  const decline = () => {
    setSize((prevSize) => {
      const newSize = prevSize - 10;
      if (newSize < 48) {
        return 48;
      }
      return newSize;
    });
  };

  return (
    <div className="container flex items-center justify-center">
      <div className="max-w-[500px] md:w-full mt-[120px] mb-[50px] shadow-md px-[40px] py-[20px] rounded-3xl bg-white">
        <h2 className="uppercase text-[20px] md:text-[32px] font-semibold mb-[20px] text-center font-sans">
          Tạo QR Code tự động
        </h2>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="w-full"
        >
          <Form.Item
            name="url"
            label="URL"
            rules={[
              { required: true },
              { type: "url", warningOnly: true },
              { type: "string", min: 6 },
            ]}
          >
            <Input placeholder="Nhập URL của bạn!" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Generator QRCode
              </Button>
            </Space>
          </Form.Item>
          {qrValues && (
            <div>
              <h2 className="text-center">Mã QR Code của bạn</h2>
              <h4 className="!mb-[8px]">Điều chỉnh kích thước QRCode</h4>
              <Button.Group style={{ marginBottom: 30 }}>
                <Button
                  onClick={decline}
                  disabled={size <= 48}
                  icon={<MinusOutlined />}
                >
                  Smaller
                </Button>
                <Button
                  onClick={increase}
                  disabled={size >= 300}
                  icon={<PlusOutlined />}
                >
                  Larger
                </Button>
              </Button.Group>
              <div ref={qrCodeRef} className="flex flex-col items-center">
                <QRCode
                  value={qrValues}
                  errorLevel="H"
                  iconSize={48}
                  size={size}
                  icon="logowow-black.png"
                  className="mb-[20px]"
                />
                <Button onClick={handleDownload}>Download QR Code</Button>
              </div>
            </div>
          )}
        </Form>
        <p className="text-[10px] font-sans italic text-right mt-[50px]">
          made by WOW Multimedia
        </p>
      </div>
    </div>
  );
}

export default App;
